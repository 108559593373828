<template>
<!-- Table Container Card -->
    <b-card no-body class="mb-0" >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entrées</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
              <b-button
                variant="primary"
                :to="{ name: 'createfrais' }"
              >
                <span class="text-nowrap">Ajouter</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
            <b-table
                id="my-table"
                class="datatables"
                :items="Datas"
                :fields="fields"
                stacked="md"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                striped
                small
                hover
                @filtered="onFiltered"
            >

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                >

                    <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                    />
                    </template>

                    <!--<b-dropdown-item :to="{ name: 'printfrais', params: { id: data.item.key } }">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Modifier</span>
                    </b-dropdown-item>-->

                    <b-dropdown-item :to="{ name: 'printfrais', params: { id: data.item.key } }">
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">Visualiser</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="deleteDatas(data.item.deleteDoc)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Supprimer</span>
                    </b-dropdown-item>
                </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >

                </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>

                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'
import { DateTime } from "luxon"

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },
    setup() {

    },
    data() {
        return {
            fields: [
                {key: 'actions', label: '', thStyle: { width: '10px' } },
                {key: 'date', label: 'Date', sortable: true, formatter: 'formatDateAssigned'},
                {key: 'name', label: 'Nom', sortable: true},
                {key: 'category', label: 'Catégorie', sortable: true},
            ],
            drawer: true,
            token: localStorage.getItem('accessToken'), //get your local storage data
            isLoading: true,
            tableData: [],
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            currentPage: 1,
            pageOptions: [5, 10, 25, 50, 100],
            filter: null,
            outlined: true,
            filterOn: [],
            sortBy: 'name',
            sortDirection: 'asc',
            sortDesc: false,
            Datas: [],
            localDB: new PouchDB('frais'),
            remoteDB: new PouchDB('https://couchdb.placedusite.fr/frais'),
        };
    },
    customButtons: {
            default: true
    },
    computed: {
        /**
        * Total no. of records
        */
        rows() {
            // return this.items.length;
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
            .filter(f => f.sortable)
            .map(f => {
                return { text: f.label, value: f.key }
            })
        }
    },
    mounted() {
        // Set the initial number of items
        // this.totalRows = this.items.length;
    },
    methods: {
        getDatas() {
            this.localDB = new PouchDB('frais')
            this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/frais')

            this.localDB.sync(this.remoteDB).on('complete', (complete) => {

                // Création d'un index de recherche
                this.localDB.createIndex({
                    index: { fields: ['type'] },
                })

                // Lecture des document sur l'index de recherhe type=clients
                this.localDB.find({ selector: { type: 'frais' } }, this.setDatas)

            }).on('error', (err) => {
            // boo, we hit an error!
                console.log("error get")
            })
        },
        setDatas(err, doc) {
            if (err) {
                console.log('err', err)
            }
            console.log('doc', doc)
            for (let i = 0; i < doc.docs.length; i++) {
                this.Datas.push({
                    key: doc.docs[i]._id,
                    rev: doc.docs[i]._rev,
                    deleteDoc: [doc.docs[i]._id, doc.docs[i]._rev],
                    name: doc.docs[i].name,
                    date: doc.docs[i].date,
                    category: doc.docs[i].category
                })
            }
            this.totalRows = this.Datas.length
        },
        formatDateAssigned(value) {
            const formattedDate = DateTime.fromISO(
                value
            ).setLocale('fr').toFormat('dd/MM/yyyy')
            return formattedDate;
        },
        deleteDatas(id) {
            this.localDB.remove(id[0], id[1])    
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Information`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Frais supprimé`,
                },
            })
            this.Datas = []
            this.getDatas()
            this.localDB.sync(this.remoteDB)
        },
        onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
        },
        addOk(idUser) {
            alert(idUser)
        },
    },
    created() {
       this.getDatas()
    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

</style>
